import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { skipWhile, switchMap, tap } from 'rxjs/operators';
import { User } from 'src/app/shared/models/user.model';
import { environment } from 'src/environments/environment';
import { AuditService, DB_ACTIONS } from './audit.service';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public user$: Observable<User>;
  public info$: Observable<any>;
  public currentUser: User;
  public info: any;

  constructor(
    protected _afAuth: AngularFireAuth,
    protected afs: AngularFirestore,
    private afunctions: AngularFireFunctions,
    private router: Router,
    private notify: NotificationService,
    private _auditService: AuditService
  ) {
    
    this.info$ = this.afs.collection('publicInfo').doc('info').valueChanges();

    this.user$ = this._afAuth.authState.pipe(
      skipWhile((user) => !user),
      switchMap((user) => {
        if (user) {
          if (!user.emailVerified) {
            return of(null);
          } else {
            return this.afs.doc<User>(`users/${user.uid}`).valueChanges();
          }
        } else {
          return of(null);
        }
      }),
      tap(async (user) => {
        if (user) {
          this.currentUser = user;
          try {
            // await this._auditService.logEvent({
            //   action: DB_ACTIONS.LOGIN_SUCCESS,
            //   object: user,
            // });
          } catch (error) {}
        }
      })
    );
    this.user$.subscribe();
  }

  getUser(): any {
    return this._afAuth.currentUser;
  }

  agreeTerms(): Promise<any> {

    return new Promise((res, rej) => {
      const callable = this.afunctions.httpsCallable('publicUserAgreeTermsUpdate');
      callable({
        version: '3.0956',
        uid: this.currentUser.uid || null
      }).subscribe(
        (value) => {
          this.router.navigate(['app/account']);
          this._auditService.logEvent({
            action: DB_ACTIONS.AGREE_TERMS
          });
          res(value);
        },
        (error) => {
          rej(error);
        },
        () => {
          res(true);
        }
      );
    });
  }

  submitMarriage(data): Promise<any> {

    return new Promise((res, rej) => {
      const callable = this.afunctions.httpsCallable('publicSubmitMarriageApplication');
      callable({
        ...data
      }).subscribe(
        (value) => {
       
          res(value);
        },
        (error) => {
          rej(error);
        },
        () => {
          res(true);
        }
      );
    });
  }

  async loginUser(email, password): Promise<void> {
    try {
      await this._auditService.logEvent({
        action: DB_ACTIONS.LOGIN_ATTEMPT,
      });
    } catch (error) {}

    this._afAuth
      .signInWithEmailAndPassword(email, password)
      .then((c) => {

        if (!c.user.emailVerified) {
          this.router.navigateByUrl('/verifyEmail');
          this._auditService.logEvent({
            action: DB_ACTIONS.LOGIN_SUCCESS_NO_EMAIL
          });
        } else {
          this.router.navigate(['app/account']);
          this._auditService.logEvent({
            action: DB_ACTIONS.LOGIN_SUCCESS
          });
        }

        
      })
      .catch(async (error) => {
        try {
          await this._auditService.logEvent({
            action: DB_ACTIONS.LOGIN_FAILURE,
          });
        } catch (error) {}

        this.notify.showConfirmOkButton('ERROR!', error);
    
      });
  }

  async resetEmailPassword(email: string): Promise<any> {
    return this._afAuth
      .sendPasswordResetEmail(email)
      .then(() => {
        this._auditService.logEvent({
          action: DB_ACTIONS.PASSWORD_RESET,
        });
        return this.notify.showConfirmOkButton(
          'SUCCESS!',
          'Check your email for a link with instructions to reset your password!'
        );
      })
      .catch((error) => {
        return this.notify.showConfirmOkButton('ERROR RESETTING PASSWORD', error);
      });
  }

  async createNewUser(credentials): Promise<any> {
    return this._afAuth
      .createUserWithEmailAndPassword(credentials.email, credentials.password)
      .then((_user) => {
        const user: User = {
          requestExempt: credentials.requestExempt || false,
          uid: _user.user.uid,
          name: credentials.name,
          email: _user.user.email,
          emailVerified: _user.user.emailVerified,
          allowedSections: {},
          landingRoute: 'emailVerification',
        };

        const p1 = _user.user.sendEmailVerification({
          url: environment.info.publicUrl,
        });

        // Callable Function to update the User Profile
        const p2 = this.afunctions
          .httpsCallable('onPublicProfileUpdate')(user)
          .toPromise()
          .catch((err) => {
            return null;
          });
        return Promise.all([p1, p2]);
      })
      .catch((err) => {
        return this.notify.showConfirmOkButton('ERROR RESETTING PASSWORD', err);
      });
  }



  logoutUser(): void {
    this._auditService.logEvent({
      action: DB_ACTIONS.LOGOUT_USER,
    });

    this._afAuth.signOut().then(() => {
      
      this.router.navigateByUrl('/login');
    }).catch(err => {
      return this.notify.showConfirmOkButton('ERROR RESETTING PASSWORD', err);
    })
  }

  public checkIfUserAlreadyExists(userEmail: string): Promise<boolean> {
    return this._afAuth.fetchSignInMethodsForEmail(userEmail).then((value) => value.length > 0);
  }

  logUserTransaction(uid: string, type: string, details: string): Promise<any> {
    return new Promise((res, rej) => {
      const d = {
        uid: uid,
        type: type,
        details: details,
        createdAt: new Date().valueOf(),
      };

      const callable = this.afunctions.httpsCallable('publicUserTransaction');
      callable({
        ...d,
      }).subscribe(
        (value) => {
          res(value);
        },
        (error) => {
          rej(error);
        },
        () => {
          res(true);
        }
      );
    });
  }

  logActivity(details: string, type: string): Promise<any> {
    const d = {
      type: type,
      details: details,
      createdAt: new Date().valueOf(),
    };
    return new Promise((res, rej) => {
      const callable = this.afunctions.httpsCallable('publicLogActivity');
      callable({
        ...d,
      }).subscribe(
        (value) => {
      
          res(value);
        },
        (error) => {
          rej(error);
        },
        () => {
          res(true);
        }
      );
    });
  }
}

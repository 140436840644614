export const environment = {
  production: true,
  info: {
    publicUrl: 'https://search.websterclerk.org/login',
    district: '26TH',
    city: 'MINDEN',
    parish: 'WEBSTER',
    url: 'search.websterclerk.org',
    logo: 'webster.png',
    clerkName: 'HOLLI VINING',
    clerkAddress: 'PO BOX 370',
    clerkCityStateZip: 'MINDEN, LA  71055',
    clerkPhone: '(318) 371-0366',
    clerkWeb: 'www.websterclerk.org',
    email: 'recording@websterclerk.org',
    civilFees: 'https://websterclerk.org/civil/',
    videoUrl: 'https://firebasestorage.googleapis.com/v0/b/cn26rec_pub/o/videos%2FClerkNet_Search_Options.mp4?alt=media&token=8accd570-7280-4109-bad5-a5a28e6b2072'
  
  },
  dates: {
    civil: 515323241000,
    criminal: 1420081885000,
    conveyances: -1696232510000,
    mortgages: -189330110000,
    recording: 473397085000,
    marriages: -2172022313000,
  },
  recaptcha: {
    siteKey: '6LdFpAEfAAAAADa2twAC3dHIEm_21UPUT4IGr4ea'
  },
  mconfig: {
    url: 'https://meili.clerknet.app',
    apiKey: '0ee5d62592b003e15706a25744b34f72bc52d03de8a5fa523f0fded6d480c6ca',
    civil: '',
    criminal: ''
  },
  sb: {
    databaseurl: 'https://igwnuvfvjjhkhpvqprja.supabase.co',
    databasekey: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imlnd251dmZ2ampoa2hwdnFwcmphIiwicm9sZSI6ImFub24iLCJpYXQiOjE2Nzc1NDA0MTYsImV4cCI6MTk5MzExNjQxNn0.eWQydn6FrTnAERCfARPpzcJde4Ho3JiOpy039lc48ho',
    i: 'cn26instruments',
    n: 'cn26names',
    url: 'https://qlcztzqvgzggxviiltlf.supabase.co',
    key: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiYW5vbiIsImlhdCI6MTY0MDY0NTkyNywiZXhwIjoxOTU2MjIxOTI3fQ.dlPcTNw2bYgfj-i6994Z7UMuLk7pg5lq_KaVbjZR4Sk'
  },
  aconfig: {
    appId: 'RZYQ3P4JBS',
    apiKey: '18f2496e8324ee32d8167b7e51f57e6d',
    CIVIL: 'websterCivil',
    RECORDING: 'webIndex',
    CRIMINAL: 'websterCriminal',
    AUDIT: 'publicAudit',
  },
  afs: {
    civ: 'cn26civil',
    civpub: 'cn26civil_pub',
    civfolder: 'cnCivil',
    crimpub: '',
    recpub: 'cn26rec_pub',
    crimfolder: '',
    crim: '',
    recfolder: '',
    rec: 'cn26recording',
    eRecording: 'cn26erecording',
    eFiling: 'cn26efiling',
  },
  firebaseConfig: {
    apiKey: 'AIzaSyBsNI7_5DLp15cgPuugKHl9ln4nuVlpFLo',
    authDomain: 'clerknetwebster.firebaseapp.com',
    databaseURL: 'https://clerknetwebster.firebaseio.com',
    projectId: 'clerknetwebster',
    storageBucket: 'cn26civil_pub',
    messagingSenderId: '1000476515333',
    appId: '1:1000476515333:web:f57f496c61b1eb48fd906c',
  },
  stripe: {
    plans: [
      {
        name: '24 HOUR',
        rate: 20,
        fee: .91,
        total: 20.91,
        discount: '',
        planId: 'price_1ItbL1EAoAVGquv0DJunKQHF'
      },
      {
        name: '1 MONTH',
        rate: 50,
        fee: 1.8,
        total: 51.80,
        discount: '',
        planIdRenewal: 'price_1ItY6DEAoAVGquv0T6xAsXhi',
        planId: 'price_1ItY6DEAoAVGquv0TWspaIt3'
      },
      {
        name: '3 MONTH',
        rate: 150,
        fee: 4.79,
        total: 154.79,
        discount: '',
        planIdRenewal: 'price_1ItY6DEAoAVGquv0MiG8rrHr',
        planId: 'price_1ItY6DEAoAVGquv0CYm23p12'
      },
      {
        name: 'YEARLY',
        rate: 570,
        fee: 17.33,
        total: 587.33,
        discount: '5% DISCOUNT',
        planIdRenewal: 'price_1ItY6DEAoAVGquv05CeZ5osJ',
        planId: 'price_1ItY6DEAoAVGquv0qwWubkfP'
      }
    ],
    clerknet: 'pk_live_514ffBuFDbduv4AUe0WNSqOQXa9Ucn7XyYE4gGbHSPTQP9laJYhc0WLAC9pn8psLh7mV6Qq8NS7ta1PzSKUygzKvA00tZ0iBHHF',
    clerknetTest: 'pk_test_514ffBuFDbduv4AUey38zdX8utYa1IYYvQ4YMNpaWcTs7yQStA7RBIRAxH4h87pPb9W79GhSa1rUQj097h1CIiGhH00GRo130Q0',
    civilAcct: 'acct_1GO1VGDVQSqq8KGf',
    recAcct: 'acct_1GcvOLEAoAVGquv0',
    connect: '',
    recording: 'pk_live_IayvbvG16Oc7MQEDDraEl6wt00vYsDyCyK',
    recordingTest: 'pk_test_rNtr8jUza7rsSto9OOXGvO7600yZRmnqXF',
    civil: 'pk_live_2Mg9hK4JpMWYJeqSL85X9aQt00arZVNbuJ',
    civilTest: 'pk_test_eFUD1LmyANJ2OZZ6AxhLSG9S00NnxPIDHZ'
  }
};

<div fxLayout="column" fxLayoutAlign="start start" style="width: 100%;">
<nb-card>
    <nb-card-body>
        <nb-alert status="primary" [closable]="false" closable>Version 3.0956 - Updated April 25, 2024</nb-alert>

        <span style="font-weight: bold; font-size: 1rem;">TERMS</span>
        <br>

        <span>By using our Site and Service, you agree to the following terms and conditions as set forth in this Agreement between you and ClerkNet Solutions, L.L.C. (also referred to as “ClerkNet Solutions”, "ClerkNet", "ClerkNet App", “Us”, “We”, or “Our”). The Services provided by ClerkNet Solutions are for end user use only.

            The Agreement Set forth herein governs your use of ClerkNet Services, through any current domain url pointing to the ClerkNet App (hereinafter “Services”). Your use of the Services includes the ability to enter into agreements and make transactions electronically. By submitting your electronic agreement, you acknowledge and agree to be bound by and to pay for the subject transaction. We are not responsible for any typographical errors.
            
            In order to access and utilize the Services offered, you may be required to have certain hardware, software, or other necessary items. You agree that it is your sole responsibility to obtain and maintain such items in order to utilize the Services.
            
            The online version of the Public Records Indices provided by the {{this.clerk.parish}} Parish Clerk of Court may contain inaccuracies and should not be relied upon as official. The official Public Records Indices are only those provided in the {{this.clerk.parish}} Parish Courthouse in {{this.clerk.city}}, Louisiana. In any situation where the official Public Records Indices differ from the online version, the official Public Records Indices will take precedence.
            
            Unless otherwise agreed to in writing between you and us, this Agreement governs your use of Services through any current domain URL pointing to the ClerkNet App.</span>

        <br><br>
        <span style="font-weight: bold; font-size: 1rem;">User Accounts</span>
        <br><br>

        Before using ClerkNet, you will need to establish an account as a registered user of our services. You are
        solely
        responsible for
        maintaining the confidentiality and security of your user account ("ACCOUNT"), and for all activities that occur
        on or
        through your Account by
        you or any User, and you agree to immediately notify us in the event of a breach regarding your Account. We
        shall
        not be responsible for any losses arising from the user failure to maintain the security of their account or the
        unauthorized use of your account.<br>

        Accounts shall be connected to the email address of the individual user utilizing the Services and sharing
        accounts
        between multiple individuals or businesses shall be prohibited.
        Services and content may include items or links to or from third parties which are provided for the purpose of
        the
        services offered or as a convenience to you. You agree that ClerkNet is not
        responsible for examining or evaluating the content or accuracy and that ClerkNet does not warrant any
        third-party materials or websites. Upon selecting a subscription, your credit card information will be provided
        to
        Stripe, our third-party payment processor, or
        any other third-party payment processor we may use. When downloading our Application, we may collect additional
        information such as your Unique Physical Device ID, IP address or other device identifiers.
        You agree that you will not use any third-party materials in a manner that would infringe or violate the rights
        of any
        other party.

        You agree that the services provided through the ClerkNet website contain proprietary information and
        material that is owned by ClerkNet, the Clerk and/or its licensors, and is protected by applicable law,
        including but
        not limited to
        copyright law. You agree that you will not use such proprietary information or materials in any way
        whatsoever except for use of the services. You agree not to reproduce, modify, rent, lease, loan, sell,
        distribute, or
        create
        derivative works based on the services or content provided by ClerkNet in any manner.

        Notwithstanding any other provision of this Agreement, ClerkNet reserves the right to change the content of
        the services provided at our discretion. In addition we reserve the right to change, suspend, remove, revoke or disable
        access to the services at any time without notice. You are not granted right or license with respect to any
        trademarks or other marks used in connection with ClerkNet’s website or services.

        ClerkNet may terminate this Agreement and/or your Account due to any non-compliance with the provisions of
        this Agreement. This termination may occur at our discretion and without prior notice to you, at which time you
        will remain liable for any amounts due up to and including the date of termination.

        It is understood by you as a User that ClerkNet does not represent, or warrant that your use will be
        uninterrupted or error-free. You expressly agree that your use is at your sole risk. ClerkNet services and
        products are
        provided "as is" and "as available", without warranties of any kind, either express or implied, including where
        applicable all implied warranties of merchantability, fitness for a particular purpose, title, and
        noninfringement.
        ClerkNet does not warrant or guarantee that services will be free from loss, corruption, attack, viruses,
        interference,
        hacking, or other security intrusion, and shall not be held liable in the event of such. In no case shall
        ClerkNet, its
        owners, employees, affiliates, agents, contractors, or licensors be liable for any direct, indirect, incidental,
        punitive, special, or consequential damages arising from your use of any of the services. It is understood that
        ClerkNet will use reasonable efforts to protect information submitted by you in connection with the service, but
        you
        agree
        that your submission of such information is at your sole risk, and ClerkNet hereby disclaims any and all
        liability to you for any and all loss or liability relating to such information. You acknowledge that credit
        card
        transactions are processed with reasonable security through a third party vendor. In addition, you as User, you
        agree,
        to the
        extent permitted by law, to indemnify and hold ClerkNet, its owners, employees, affiliates, agents, contractors,
        or
        licensors harmless with respect to any claims arising out of your use of service or your breach of this
        agreement.
        <br>
        <br>
        <span style="font-weight: bold; font-size: 1rem;">ClerkNet - Privacy Policy</span>
        <br>
        <span>
            This privacy policy discloses the privacy practices for url's directing to the ClerkNet App. This privacy
            policy
            applies solely to information collected by this web site. The policy identifies what personally identifiable
            information is collected from you through the web site, how it is used and with whom it may be shared, what
            choices
            are
            available to you regarding the use of your data, and the security procedures in place to protect the misuse
            of your
            information. ClerkNet App is herein referred to as “ClerkNet” or “We”.
        
            We only have access to/collect information that you voluntarily give us via email or other direct
            contact from you on this site. Visitors to this website who have javascript enabled are tracked using Google
            Analytics.
            Google Analytics collects the following types of information from users:
            - Type of user agent (web browser) used, software manufacture and version number.
            - Type of operating system
            - Screen colors (color processing ability of the users screen)
            - Javascript support
            - Flash version
            - Screen resolution
            - Network location and IP address
            - Can include country, city, state, region, county, or any other geographic data.
            - Hostname
            - Bandwidth (internet connection speed)
            - Time of visit
            - Pages visited
            - Cases, Instruments, Items, Documents, Images
            - Time spent on each page of the website
            - Referring site statistics
            - The website (URI) the user came through in order to arrive at this website (example: clicking on a
            hyperlink from
            Yahoo.com that took the user to this website)
            - Search engine query used (example: typing in a phrase into a search engine like Google, and clicking on a
            link
            from that search engine)
        
            We request contact information (like name and address) and financial information (like credit card
            number, expiration date) to process orders of the product or services on this website. This information is
            used for billing
            purposes and
            to provide the services requested. If we have trouble processing an order, we'll use this information to
            contact you.
            We will also use your information to respond to you, regarding the reason you contacted us. In the event
            we partner
            with another party to provide specific services we may share names, or other contact information that is
            necessary
            for the third party to provide these services. These parties are not allowed to use personally
            identifiable
            information except for the purpose of providing these services. We also use your information to help us
            develop,
            deliver, and improve our products, services, content, and advertising. Unless you ask us not to, we may
            contact you
            via email in the future to tell you about specials, new products or services, or changes to this privacy
            policy. You
            may opt out of any future contacts from us at any time by contacting us or emailing us. You may also
            present
            questions regarding your information or this policy at any time by contacting us via the email address
            or phone
            number given on our website.
        
            In the event of legal process, litigation, and/or requests from public and governmental authorities
            within or outside
            your State, it may be necessary for us to disclose your information in accordance with the law. We may
            also disclose
            information about you if we determine that for purposes of national security, law enforcement, or other
            issues of
            public importance, disclosure is necessary or appropriate. We may also disclose information about you if
            we determine
            that disclosure is reasonably necessary to enforce our terms and conditions or protect our operations or
            users.
            Additionally, in the event of a reorganization, merger, or sale we may transfer any and all personal
            information we
            collect to the relevant third party.
        
            We take precautions, online and offline, to protect sensitive information submitted via the website.
            Whenever
            sensitive information, such as credit card data, is collected that information is encrypted and
            transmitted in a
            secure way using third party vendor : Stripe. The use of the third party vendor for payment processing
            is made
            apparent where you enter your information. While we use encryption to protect certain sensitive
            information
            transmitted online, we also protect your information offline. Only employees who need the information to
            perform a
            specific job related to our business services are granted access to personally identifiable information.
        
            This web site contains links to other sites. Please be aware that we are not responsible for the content
            or privacy
            practices of such other sites. We encourage our users to be aware when they leave our site and to read
            the privacy
            statements of any other site that collects personally identifiable information. We reserve the right to
            update and
            making changes to our policies. All updates will be posted on this page, or sent to your attention via
            email or any
            other preferred contact method we have on file for you. If you feel that we are not abiding by this
            policy or any
            portion thereof, we request that you contact us immediately through the email address provided on our
            website.
        </span>
        <br><br>
        <span style="font-weight: bold; font-size: 1rem;">IMAGES AND COPYRIGHTS</span>
        <br>
        <span style="font-weight: bold; text-transform: uppercase;">
            All images on ClerkNet are protected by copyright and are for viewing purposes only. Without the authority of the
            copyright owner, which is the Clerk of Court, downloading any image is an infringement of the copyright owner's
            exclusive rights of reproduction and/or distribution. If found to have infringed a copyrighted work, you may be
            liable for statutory damages up to $30,000 for each work infringed. If willful infringement is proven by the
            copyright owner, that amount may be increased up to $150,000 for each work infringed. In addition, an infringer of a
            work may also be liable for the attorney's fees incurred by the copyright owner to enforce his or her rights.
        </span>
        <br><br>


        <span style="font-weight: bold; font-size: 1rem;">INTELLECTUAL PROPERTY RIGHTS</span>
        <br>
        <span style="font-weight: bold; text-transform: uppercase;">
            The design of ClerkNet, including the algorithms, code, text, scripts, graphics, features, and service marks, are owned by or licensed to ClerkNet Solutions, LLC, and are subject to copyright and other intellectual property rights. ClerkNet reserves all rights not expressly granted in and to the Service and the Site. You may not use, copy, or distribute anything contained within the Site or Service without express written permission from us.</span>
        <br><br>

        <span style="font-weight: bold; font-size: 1rem;">EMAILS</span>
        <br>
        <span style="font-weight: bold; text-transform: uppercase;">
            We may send you emails about our products and services, as well as other information that we think you may find of interest. You can unsubscribe from these emails at any time by clicking on the "unsubscribe" link at the bottom of any email or contact support@clerknet.app
</span>
        <br><br>


        <span style="font-weight: bold; font-size: 1rem;">CLERKNET - PAYMENT AND REFUND POLICY</span>
        <br>
        <span style="font-weight: bold; text-transform: uppercase;">
            Due to the nature of our software and the immediate access to our information products, refunds are not available. By signing up for a recurring subscription, you acknowledge and agree that you are authorizing recurring payments, and that such payments are non-refundable. We do not offer refunds, in whole or in part, for any reason, including but not limited to unused subscription periods, change of mind, or dissatisfaction with the service. Further, THERE ARE NO REFUNDS FOR CLERKNET PRE-PAID FUNDS ARE NON-REFUNDABLE AND WILL REMAIN ASSOCIATED WITH THE ACCOUNT UNTIL UTILIZED FOR
            PURCHASING PRINTS OR DOWNLOADS. <br>
            ** SUBSCRIPTION FEES ARE COLLECTED AT THE TIME OF THE SUBSCRIPTION INITIATION OR SUBSCRIPTION RENEWAL AND ARE NON-REFUNDABLE.
            ONCE A SUBSCRIPTION IS PURCHASED, THE ACCOUNT WILL REMAIN ACTIVE FOR THE TIME PERIOD INCLUDED WITH THE
            PURCHASED SUBSCRIPTION. 
            <br>
            Disabling auto-renewal prior to the subscription renewal taking place is the responsibility of the user. Users who wish to terminate their subscription must do so by disabling auto-renewal through their account settings before the renewal date. Failure to disable auto-renewal will result in the automatic renewal of the subscription and the user will be charged for the next subscription period. We are not responsible for any charges incurred as a result of the user's failure to disable auto-renewal.
            
            ONCE A SUBSCRIPTION HAS AUTO-RENEWED, THE USER HAS IMMEDIATE ACCESS TO DATA AND/OR INFORMATION AVAILABLE ON THE SITE, THEREFORE NO REFUNDS WILL BE ISSUED. 
        </span>
        <br><br>
        <span style="font-weight: bold; font-size: 1rem;">CLERKNET - NON-REFUNDABLE CREDIT CARD PROCESSING FEE
            POLICY</span>
        <br>
        <span style="font-weight: bold; text-transform: uppercase;">
            All Credit Card Transactions are assesed a fee in the amount of 2.9% + $0.30 (thirty cents) per transaction
            for credit card processing. Clerknet WILL NOT return any portion of credit card fees collected during ANY TRANSACTION, SUBSCRIPTION AND/OR COPY/PRINT FUNDS, as these are collected via a third-party vendor, Stripe.
        </span>
        <br><br>
        <span style="font-weight: bold; font-size: 1rem;">CLERKNET - NON-REFUNDABLE ACH DIRECT DEBIT/E-CHECK PROCESSING
            FEE POLICY</span>
        <br>
        <span style="font-weight: bold; text-transform: uppercase;">
            All ACH Direct Debit Transactions are assesed a fee in the amount of 0.8% per transaction with a $5.00 cap
            for
            ACH Direct Debit processing. Clerknet WILL NOT return any portion of ACH Direct Debit fees collected during
            the initial
            transaction as these are collected via a third-party vendor, Stripe. Users must accept a mandate authorizing
            you to debit
            their account and verify ownership of the account through instant verification or micro-deposits.
        </span>
        <br><br>

        <span style="font-weight: bold; font-size: 1rem;">ACH DIRECT DEBIT MANDATE</span>
        <br>
        <span style="font-weight: bold; text-transform: uppercase;">
            Users have the option to add an ACH Direct Debit payment method in which ClerkNet will directly debit the
            user's bank account for charges. By adding a Bank Account ACH Direct Debit Payment Method, you authorize
            ClerkNet and/or the Clerk of Court to debit the bank account provided for any amount owed for charges
            arising from your use of ClerkNet services and/or purchase of products from ClerkNet and/or Clerk of Court,
            pursuant to CLERKNET TERMS AND PRIVACY POLICY, until this authorization is revoked. You may amend or cancel
            this authorization at any time by providing notice to ClerkNet and/or Clerk of Court with 30 (thirty) days
            notice.
            <br><br>
            If you use ClerkNet services or purchase additional products periodically pursuant to CLERKNET TERMS AND
            PRIVACY POLICY, you authorize ClerkNet and/or Clerk of Court to debit your bank account periodically.
            Payments that fall outside of the regular debits authorized above will only be debited after your
            authorization is obtained.
        </span>
        <br><br>

        <span style="font-weight: bold; font-size: 1rem;">COPY/PRINT/PDF DOWNLOAD RATES</span>
        <br>
        <span>
            24 HOUR SUBCRIPTIONS WILL COST $1.50 PER PAGE FOR COPIES/PRINTS/PDF DOWNLOADS. ALL OTHER SUBSCRIPTIONS ARE
            $1.00 PER PAGE. ALL RATES ARE SUBJECT TO CHANGE AT THE DISCRETION OF THE CLERK OF COURT.
        </span>
        <br><br>
        <span style="font-weight: bold; font-size: 1rem;">CHARGEBACK/DISPUTE POLICY</span>
        <br>
        <span>
            IN THE EVENT OF A DISPUTE, CLAIMS, OR CHARGEBACK, YOU AGREE TO SUBMIT YOUR CLAIMS TO THE CLERK OF COURT AND/OR
            CLERKNET VIA EMAIL WITHIN 24 HOURS OF THE CHARGE. All claims will be reviewed and you will receive a response from the Clerk of Court and/or ClerkNet within 7 days.

        </span>
        <br><br>
        <span style="font-weight: bold; font-size: 1rem;">ClerkNet Recording Alerts</span>
        <br><br>

        COMING SOON!
        <br><br>
        <span style="font-weight: bold; font-size: 1rem;">CLERKNET E-RECORDING</span>
        <br>
        <span>
            ClerkNet E-Recording is a solution allowing users to submit electronic documents to the Clerk of Court for
            processing.

        </span>
        <app-terms-erecording></app-terms-erecording>
        <br><br>
        <span style="font-weight: bold; font-size: 1rem;">CLERKNET E-FILING</span>
        <br>
        <span>
            ClerkNet E-Filing is a solution that allows attorneys of record to file a new petition or submit a filing on
            an existing case. Members of the Louisiana Bar Association who practice in the {{clerk.district}} Judicial
            District may take advantage of the ClerkNet E-Filing capabilities that are now available.
            <br>
        </span>
        <app-terms-efiling></app-terms-efiling>

     
    </nb-card-body>
</nb-card>
<span style="font-size: 11px;" fxFlex>© 2024 ClerkNet Solutions, LLC. All Rights Reserved.</span>
</div>